<template>
  <div class="max-w-xs mx-auto">
    <div class="bg-white shadow-md border border-gray-400 rounded-lg max-w-sm mb-3">
      <div class="p-5">
        <h5 class="text-gray-900 font-bold text-2xl text-center tracking-tight mb-4">
          Restablecer contraseña
        </h5>
        <p class="text-center mt-4 mb-2" v-if="mensaje">
          Escribe el código que te enviamos al correo y tu nueva contraseña
        </p>
        <p class="text-center mt-4 mb-2" v-if="!mensaje">Escribe tu nueva contraseña</p>
        <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
          <a-form-item name="codigo" class="text-center" v-if="codigo">
            <div id="divOuter">
              <div id="divInner">
                <a-input
                  v-model:value="formState.codigo"
                  id="partitioned"
                  :maxlength="6"
                  @keyup="CodeVerificar"
                />
              </div>
            </div>
          </a-form-item>
          <a-form-item name="correo" v-if="correo">
            <a-input
              v-model:value="formState.correo"
              type="email"
              placeholder="Correo electrónico"
              :disabled="existEmailState"
            >
              <template #prefix><MailOutlined style="color: rgba(0, 0, 0, 0.25)" /> </template>
            </a-input>
          </a-form-item>
          <a-form-item has-feedback name="password">
            <a-input-password
              v-model:value="formState.password"
              type="password"
              placeholder="Contraseña"
              id="password"
            >
              <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /> </template>
            </a-input-password>
          </a-form-item>
          <a-form-item has-feedback name="confirm_password">
            <a-input-password
              v-model:value="formState.confirm_password"
              type="password"
              placeholder="Confirmar Contraseña"
            >
              <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
            </a-input-password>
          </a-form-item>

          <a-button
            type="primary"
            class="font-bold text-xs"
            htmlType="submit"
            :loading="isLoading"
            block
            @click="onSubmit"
            >RESTABLECER CONTRASEÑA</a-button
          >

          <a-alert v-if="hasError" class="mt-4" :description="hasError" type="error" closable />

          <a-button type="link" block class="mt-2 font-semibold text-xs">
            <router-link to="forgot-password">SOLICITAR NUEVO CÓDIGO</router-link>
          </a-button>

          <a-button type="link" block class="font-semibold text-xs" v-if="mensaje">
            <router-link to="login">IR A INICIAR SESIÓN</router-link>
          </a-button>
        </a-form>
      </div>
    </div>
  </div>
  <!-- end card -->
</template>

<script>
import { reactive, toRaw, ref, readonly, onMounted } from "vue";
import { LockOutlined, MailOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import { hasNumber, hasMayus } from "@/utils";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import publicIp from "public-ip";

export default {
  components: {
    // icons
    LockOutlined,
    MailOutlined
  },
  setup() {
    const formRef = ref();
    const isLoading = ref(false);
    const hasError = ref("");
    const codigo = ref(true);
    const mensaje = ref(true);
    const correo = ref(true);
    const store = useStore();
    const route = useRoute();
    const existEmailState = readonly(!!store.state.user.emailRecover);

    const formState = reactive({
      correo: store.state.user.emailRecover,
      codigo: "",
      password: "",
      usuario: "",
      confirm_password: ""
    });

    const datos = reactive({
      usuario: "",
      constrasenia: "",
      ip: ""
    });

    const onSubmit = () => {
      hasError.value = "";

      if (formState.password !== formState.confirm_password) {
        hasError.value = "Las contraseñas no coindicen";
        return;
      }

      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          console.log(payload);

          isLoading.value = true;
          store
            .dispatch("user/recover", payload)
            .then(async (response) => {
              notification.success({
                message: "Su contraseña se ha restablecido correctamente"
              });

              console.log("response recover", response);

              datos.ip = await publicIp.v4();
              datos.constrasenia = formState.password;

              console.log(datos);

              store.dispatch("user/login", datos).catch((error) => {
                console.log("error login after recover", error);
                hasError.value = error.message;
              });
            })
            .catch((error) => {
              console.log(error);
              hasError.value = error.message;
            })
            .finally(() => (isLoading.value = false));
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    onMounted(() => {
      if (route.query.email != null && route.query.email.length > 0) {
        correo.value = false;
        mensaje.value = false;
        formState.correo = route.query.email;
        formState.codigo = route.query.codigo;
        datos.usuario = route.query.user;

        if (route.query.codigo != null && route.query.codigo.length > 0) codigo.value = false;
      }
    });

    // rules
    let validatePass = async (rule, value) => {
      if (value === "") {
        formState.confirm_password = "";
        return Promise.reject("La contraseña actual es requerida");
      } else if (formState.confirm_password !== "") {
        formRef.value.validateFields("password");
      } else if (!hasNumber(value)) {
        return Promise.reject("Debe contener al menos un número");
      } else if (!hasMayus(value)) {
        return Promise.reject("Debe contener al menos un caracter en mayúscula");
      } else if (value.length < 6) {
        return Promise.reject("La cantidad mínima de caracteres es de 6");
      } else {
        return Promise.resolve();
      }
    };

    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("Repetir la nueva contraseña es requerida");
      } else if (value !== formState.password) {
        return Promise.reject("Las contraseñas no coinciden");
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      codigo: [
        { required: true, message: "El campo código no puede estar vacío", trigger: "blur" },
        { max: 6, message: "Deben ser 6 caracteres" }
      ],
      correo: [
        {
          required: true,
          message: "El campo correo no puede estar vacío",
          trigger: "blur"
        },
        {
          type: "email",
          message: "Formato de correo inválido"
        }
      ],
      password: [
        {
          required: true,
          validator: validatePass,
          trigger: "change"
        },
        { max: 20, message: "Deben ser a lo mucho 20 caracteres" }
      ],
      confirm_password: [
        {
          validator: validatePass2,
          trigger: "change"
        },
        { max: 20, message: "Deben ser a lo mucho 20 caracteres" }
      ]
    };

    // end rules

    // KEY UP

    const CodeVerificar = (e) => {
      if (e.target.value.length === 6) {
        document.getElementById("password").focus();
      }
    };

    return {
      CodeVerificar,
      formRef,
      formState,
      onSubmit,
      rules,
      isLoading,
      codigo,
      correo,
      hasError,
      existEmailState,
      mensaje
    };
  }
};
</script>

<style scoped>
#partitioned {
  padding-left: 18px;
  letter-spacing: 30px;
  font-size: 19px;
  border: 0;
  background-image: linear-gradient(to left, black 55%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 40px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 250px;
  min-width: 250px;
  outline: none;
}

#divInner {
  left: 0;
  position: sticky;
}

#divOuter {
  margin: auto;
  width: 200px;
  overflow: hidden;
}
</style>
